import { Injectable } from "@angular/core";
import { ProductsModel } from "src/app/models/products/product.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ProductsGuestService {
    private url = "http://138.2.159.102:3000";
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
    constructor(private http: HttpClient){

    }

    public async getProductByToken(token: string): Promise<ProductsModel>{
        var result = await new Promise(resolve => {
            this.http.post<ProductsModel>(this.url + "/getProductByToken", {token}, this.httpOptions)
                .subscribe(async data => {
                    var checkUser = data as ProductsModel;
                    if(checkUser){
                        resolve(checkUser);
                    }
                }, error => {
                    resolve(error.error.text);
                });
        });

        return result as ProductsModel;
    }
}