<div class="page-wrapper">
  <header class="page-header">
      <div class="page-header__inner">
          <div class="page-header__sidebar">
              <div class="page-header__menu-btn"><button class="menu-btn ico_menu is-active"></button></div>
              <div class="page-header__logo"><img src="../../assets/imgs/logo1.png" alt="logo"></div>
          </div>
          <div class="page-header__content">
              <div class="page-header__search">
                  <div class="search">
                      </div>
              </div>
              <div class="page-header__action" [routerLink]="['/profile']"><a class="profile" href="#!"><img src="assets/imgsN/profile.png" alt="profile"></a></div>
          </div>
      </div>
  </header>
  <div class="page-content">
    <aside class="sidebar is-show" id="sidebar">
        <div class="sidebar-box">
          <ul class="uk-nav">
            <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
            <li class="uk-nav-header">Konto</li>
            <li><a href="04_profile.html" [routerLink]="['/profile']"><i class="ico_profile"></i><span>Profil</span></a></li>
            <li><a href="05_favourites.html" [routerLink]="['/liked']"><i class="ico_favourites"></i><span>Ulubione</span></a></li>
            <li class="uk-nav-header">Portal</li>
            <li><a href="09_games-store.html" [routerLink]="['/dashboard']"><i class="ico_store"></i><span>Kategorie</span></a></li>
            <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
            <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
            <li class="uk-nav-header">Pomoc</li>
            <li><a href="#modal-report" data-uk-toggle><i class="ico_report"></i><span>Więcej o platformie</span></a></li>
            <li><a href="#modal-help" data-uk-toggle><i class="ico_help"></i><span>Pomoc</span></a></li>
        </ul>
        </div>
    </aside>
    <main class="page-main">
      <ul class="uk-breadcrumb">
        <li (click)="goBack()"><span data-uk-icon="chevron-left"></span><span>Cofnij</span></li>
    </ul>
      <div *ngIf="(product | async)">
      <h3 class="uk-text-lead">{{(product | async).title}}</h3>
      <div class="uk-grid uk-grid-small" data-uk-grid>
          <div class="uk-width-3-3@xl">
              <div class="gallery">
                  <div class="js-gallery-big gallery-big">
                      <div class="bg-img" [ngStyle]="{'background-image': (product | async)?.image?.length < 100 ? 'url(assets/imgs/szoppop.jpg)' : (product | async)?.image?.includes('data:image') ? 'url('+ (product | async)?.image +')' : 'url(data:image/png;base64,'+ (product | async)?.image +')'}"></div>
  
                  </div>
              </div>
          </div>

          <div class="uk-width-2-3@xl" *ngIf="(product | async)?.site != 'amazon'">
            <div class="month_div" *ngIf="!isGuest">
              <ion-select interface="popover" [(ngModel)]="selectedChartFilter" (ionChange)="changeFilterChart()" class="popover_div">
                <ion-select-option value="w">2 Tygodnie</ion-select-option>
                <ion-select-option value="m">Miesiąc</ion-select-option>
                <!--<ion-select-option value="6m">6 Miesięcy</ion-select-option>
                <ion-select-option value="r">Rok</ion-select-option>-->
              </ion-select>
            </div>
            <div *ngIf="!isGuest;else showGuestDiagram">
              <div *ngIf="!(loadingChart | async);else chartLoading">
                <canvas baseChart style="width: 100%;" height="250" 
                    [data]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType">
                </canvas>
              </div>
              <ng-template #chartLoading>
                <div class="loadingSpinner">
                  <ion-spinner></ion-spinner>
                </div>
              </ng-template>
            </div>
            <ng-template #showGuestDiagram>
              <div *ngIf="!(showComments | async)" class="no-comments">
                <ion-icon name="close-outline"></ion-icon> Wykres cen ukryty-Zaloguj się!
              </div>
            </ng-template>
        </div>
          <div class="uk-width-1-3@xl" *ngIf="(product | async)?.site != 'amazon';else amazonsite">
              <div class="game-profile-card">
                  <ul class="game-profile-card__list">
                      <li>
                          <div>Strona:</div>
                          <div>{{getSite()}}</div>
                      </li>
                      <li>
                          <div>Zniżka:</div>
                          <div style="color: red;">-{{((product | async)?.discount * 100).toFixed(0)}}%</div>
                      </li>
                      <li>
                          <div>Aktualizacja:</div>
                          <div>{{(product | async)?.searchDate | date: 'dd/MM/yyyy'}}</div>
                      </li>
                      <ul class="game-profile-card__type">
                        <li>
                            <ion-button (click)="generateToken()" slot="end" fill="clear">
                                <ion-icon *ngIf="!isGuest" name="share-social-outline"></ion-icon>
                            </ion-button>
                            <ion-button (click)="addLike()" fill="clear">
                                <ion-icon (click)="addLike()" [name]="likeIcon | async" style="color: black;"></ion-icon>
                            </ion-button>
                        </li>
                    </ul>
                  </ul>
              </div>
              <div class="game-profile-price">
                  <div>Cena promocyjna</div>
                  <div class="game-profile-price__value">{{((product | async).price).toFixed(2)}}zł</div>
                  <div>Początkowa cena</div>
                  <div class="game-profile-price__value">{{((product | async).oryginalPrice).toFixed(2)}}zł</div>
                  <button class="uk-button uk-button-danger uk-width-1-1" type="button" (click)="openUrl(product)"><span class="ico_shopping-cart"></span><span>Otwórz</span></button>
                </div>
          </div>
          <ng-template #amazonsite>
            <div class="uk-width-1-3@xl">
              <div class="game-profile-card">
                  <ul class="game-profile-card__list">
                      <li>
                          <div>Strona:</div>
                          <div>{{getSite()}}</div>
                      </li>
                      <li>
                          <div>Zniżka:</div>
                          <div style="color: red;">-{{((product | async)?.lowest.discount * 100).toFixed(0)}}%</div>
                      </li>
                      <li>
                          <div>Aktualizacja:</div>
                          <div>{{(product | async)?.searchDate | date: 'dd/MM/yyyy'}}</div>
                      </li>
                      <ul class="game-profile-card__type">
                        <li>
                            <ion-button (click)="generateToken()" slot="end" fill="clear">
                                <ion-icon *ngIf="!isGuest" name="share-social-outline"></ion-icon>
                            </ion-button>
                            <ion-button (click)="addLike()" fill="clear">
                                <ion-icon (click)="addLike()" [name]="likeIcon | async" style="color: black;"></ion-icon>
                            </ion-button>
                        </li>
                    </ul>
                  </ul>
              </div>
              <div class="game-profile-price">
                  <div>Cena promocyjna</div>
                  <div class="game-profile-price__value">{{((product | async)?.lowest?.price).toFixed(2)}}zł</div>
                  <button class="uk-button uk-button-danger uk-width-1-1" type="button" (click)="openUrlAmazon(product)"><span class="ico_shopping-cart"></span><span>Otwórz</span></button>
                </div>
                <div class="container" id="app">
                  <div class="board">
                  <h2 class="ion-row-header">Leaderboard</h2>
                  <div *ngFor="let countryItem of this.countryPrices">
                      <li class="cat-item" *ngIf="countryItem.price > 0 && countryItem.price < 100000000000" (click)="openUrlAmazon(product, countryItem.name)">
                      <div class="cat-item__photo">
                          <ion-img [src]="getCountrFlag(countryItem.name)"></ion-img>
                      </div>
                      <div class="cat-item__info">
                          <h4>{{ countryItem.fullName }}</h4>
                      </div>
                      <div class="cat-item__points">
                          <p>{{ countryItem.price }}</p>
                      </div>
                      </li>
                  </div>
                  </div>
              </div>
          </div>
          </ng-template>
          
      </div>
      </div>
       
    </main>
</div>