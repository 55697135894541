import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { ShopsBrowserViewComponent } from "src/browser/other-shops/shops.component";
import { ShopsMobileViewComponent } from "src/mobile/other-shops/shops.component";
import { routesBrowser, routesMobile } from "./routes.model";

@Injectable()
export class NavigationFactoryService {
    constructor(private platform: Platform, private route: Router){}

    public getComponent(route: string): any{
        if(!route){
            this.route.navigate(['/login']);
        }
        var componentLoad;
        if(this.platform.is('android' || 'mobile')){
            componentLoad = routesMobile.find(x => x.id.split('/')[0] == route);
            if(componentLoad){
                return componentLoad;
            }else{
                return ShopsMobileViewComponent;
            }
          }else {
            componentLoad = routesBrowser.find(x => x.id.split('/')[0] == route);
            if(componentLoad){
                return componentLoad;
            }else{
                return ShopsBrowserViewComponent;
            }
        }
    }
}