<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu is-active"></button></div>
                <div class="page-header__logo"><img src="../../assets/imgs/logo1.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    
                </div>
                <div class="page-header__action" [routerLink]="['/profile']"><a class="profile" href="#!"><img src="assets/imgsN/profile.png" alt="profile"></a></div>
          </div>
        </div>
    </header>
    <div class="page-content">
        <aside class="sidebar is-show" id="sidebar">
            <div class="sidebar-box">
              <ul class="uk-nav">
                <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
                <li class="uk-nav-header">Konto</li>
                <li><a href="04_profile.html" [routerLink]="['/profile']"><i class="ico_profile"></i><span>Profil</span></a></li>
                <li><a href="05_favourites.html" [routerLink]="['/liked']"><i class="ico_favourites"></i><span>Ulubione</span></a></li>
                <li class="uk-nav-header">Portal</li>
                <li><a href="09_games-store.html" [routerLink]="['/dashboard']"><i class="ico_store"></i><span>Kategorie</span></a></li>
                <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
                <li class="uk-nav-header">Pomoc</li>
                <li><a href="#modal-report" data-uk-toggle><i class="ico_report"></i><span>Więcej o platformie</span></a></li>
                <li><a href="#modal-help" data-uk-toggle><i class="ico_help"></i><span>Pomoc</span></a></li>
            </ul>
            </div>
        </aside>

        <main class="page-main">
            <div *ngIf="!(loading | async);else loadingScreen">
                <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                    <div *ngFor="let item of (categoryList | async); let i = index">
                        <div class="game-card" [routerLink]="['/list']" 
                        [queryParams]="{ type: ''+item.Category+'', main: ''+categoryTitle.getValue()+''}">
                            <div class="game-card__box">
                                <div class="game-card__media" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item) +'.jpg)'"></div>
                                <div class="game-card__info">{{item.Category}}</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <ng-template #loadingScreen>
                <div class="loadingSpinner">
                    <ion-spinner></ion-spinner>
                  </div>
              </ng-template>
        </main>
    </div>