<div class="page-login">
  <div class="page-wrapper">
    <main class="page-first-screen">
        <div class="uk-grid uk-grid-small uk-child-width-1-2@s uk-flex-middle uk-width-1-1" data-uk-grid>
            <div class="logo-big">
                <img src="assets/imgs/logo1.png" alt="logo">
                <span>Amazpep</span>
                <h1>Bo wszystko sprowadza się do jednego...</h1>
            </div>
            <div>
                <div class="form-login">
                    <div class="form-login__social">
                        <ul class="social">
                            <li><a href="https://www.facebook.com/profile.php?id=100087825745624"><img src="assets/img/facebook.svg" alt="facebook"></a></li>
                        </ul>
                    </div>
                    <div class="form-login__box">
                        <div class="uk-heading-line uk-text-center"><span>Zapomniałe hasła</span></div>
                        <form action="#!">
                            <div class="uk-margin"><input class="uk-input" type="text" name="code" placeholder="Code" [(ngModel)]="code"></div>
                            <div class="uk-margin"><input class="uk-input" type="text" name="password" placeholder="Password" [(ngModel)]="password"></div>
                            <div class="uk-margin uk-button uk-button-danger uk-width-1-1" (click)="sendForgotRequest()">Wyślij</div>
                            <hr>
                            <div class="uk-text-center" (click)="goRegister()"><span>Nie posiadam konta?</span>>Rejestracja</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </div>
</div>