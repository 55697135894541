<ion-header mode="ios">
    <ion-img src="assets/imgs/logoreklama.png" class="img-logo" ></ion-img>
    <ion-toolbar>
        
        <ion-button (click)="goToBack()" slot="start" fill="clear">
            <ion-icon name="arrow-back-sharp" style="color: black;"></ion-icon>
        </ion-button>
        <ion-title class="titleHide">{{title | async}}</ion-title>
        <ion-button (click)="openFilter()" slot="end" fill="clear">
            <ion-icon name="filter-outline" style="color: black;"></ion-icon>
        </ion-button>
    </ion-toolbar>
    <div class="search" id="search">
        <ion-input (keyup.enter)="onEnter()" (keyup)="onKeySearch($event)" [(ngModel)]="keyword" id="searchBox" type="text" placeholder="Wyszukaj...">
            <ion-icon name="search"></ion-icon>
        </ion-input>
    </div>
    <div class="header_div" *ngIf="(showSubCategory | async) && (subCategory | async)?.length > 0">
        <ion-label *ngFor="let item of subCategory | async; let i = index" 
        [class.active]="checkSubCategory(item)" 
        (click)="startChangeSubCategory(item)">{{item}}</ion-label>
    </div>
</ion-header>
<ion-content [style.height.%]="(showSubCategory | async) && (subCategory | async)?.length > 0 ? 72 : 79" >
    <div class="main_content_div">
        <div>
            <div>
                <div class="card_box" *ngFor="let item of (productList | async)">
                    <div class="back_image" [ngStyle]="{'backgroundImage': item?.image?.length < 100 ? 'url(assets/imgs/szoppop.jpg)' : item?.image?.includes('data:image') ? 'url('+ item?.image +')' : 'url(data:image/png;base64,'+ item?.image +')'}"  (click)="openProduct(item)"></div>
                    <div [class]="item.voteSum == 0 ? 'vote' : item.voteSum < 0 ? 'vote votes-down' : 'vote votes-up'" (click)="openProduct(item)">{{ item.voteSum }}</div>
                    <div class="detail_div" (click)="openProduct(item)">
                        <ion-label class="bold_name">{{item.title | slice:0:80}}</ion-label>
                        <ion-label class="addr_lbl" *ngIf="item.site != 'amazon'">{{item.site}}</ion-label>
                        <ion-label class="addr_lbl" *ngIf="(item.site) == 'amazon'">{{item.site}} - {{item.lowest.country | uppercase}}</ion-label>
                    </div>
                    <div class="last_div">
                        <div class="color_box" (click)="openProduct(item)"><ion-label>{{item.price ?? item.lowest.price}}zł</ion-label></div>
                        <div *ngIf="item.site != 'amazon' && item?.discount != 0" class="color_box_discount" (click)="openProduct(item)"><ion-label>-{{(item.discount * 100).toFixed(1)}}%</ion-label></div>
                        <div *ngIf="item.site == 'amazon' && item?.lowest?.discount != 0" class="color_box_discount" (click)="openProduct(item)"><ion-label>-{{(item.lowest.discount * 100).toFixed(0)}}%</ion-label></div>
                        <ion-icon [name]="iconSelect(item)" class="icon-like" (click)="addLike(item)"></ion-icon>
                    </div>
                </div>
            </div>
            
            <ion-infinite-scroll *ngIf="!(endProduct | async)" (ionInfinite)="doInfinite($event)">
                <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne produkty...">
                </ion-infinite-scroll-content>
              </ion-infinite-scroll>
              <div *ngIf="(endProduct | async)" class="no-product">
                Brak produktów!
              </div>
        </div>
    </div>
    <ion-refresher slot="fixed" (ionRefresh)="getNextItems($event, true, false)" class="refresh_box">
        <ion-refresher-content
          pullingIcon="chevron-down-circle-outline"
          pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing..."
          class="background_content">
        </ion-refresher-content>
      </ion-refresher>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button (click)="scrollUp()" class="button-float">
          <ion-icon name="chevron-up-circle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
</ion-content>