import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, ROUTES, Routes } from '@angular/router';
import { AuthGuardGuestService } from './services/auth/auth-guard-guest.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { NavigationService } from './services/route/navigation.service';
import { guards, routesBrowser, routesMobile } from './services/route/routes.model';

@NgModule({
  imports: [
    RouterModule.forRoot([], { preloadingStrategy: PreloadAllModules, useHash: true }) //undefined, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        let routes: Routes = [];
        var routeAll = [...routesMobile, ...routesBrowser];
        var routeAllUnique = routeAll.filter((item, pos) => routeAll.indexOf(item) === pos)
        routeAllUnique.forEach(rou => {
          routes.push({
            path: rou.id,
            component: NavigationService,
            canActivate: [guards.find(x => x?.type == rou?.guard)?.serviceRef ?? AuthGuardService],
          });
        });
        console.log(routes);
        
        return [
          ...routes,
        ];
      },
      multi: true,
    }
  ]
})
export class AppRoutingModule {}
