<ion-header mode="ios" style="background: white;">
  <ion-img src="assets/imgs/logoreklama.png" class="img-logo" ></ion-img>
  <ion-toolbar>
      <ion-button (click)="close()" slot="start" fill="clear" style="z-index: 200;">
          <ion-icon (click)="close()" name="arrow-back-sharp" style="color: black;"></ion-icon>
      </ion-button>
      <ion-title slot="start">{{product.title | slice:0:15}}</ion-title>
      <ion-button (click)="addLike(product)" slot="end" fill="clear" style="z-index: 200;">
        <ion-icon (click)="addLike(product)" [name]="likeIcon | async" style="color: black;"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen #scrollContent scrollEvents="true" style="--background: #EFF0F5;">
  <ion-header>
    <div class="bg-img" [ngStyle]="{'background-image': product?.image?.length < 100 ? 'url(assets/imgs/szoppop.jpg)' : product?.image?.includes('data:image') ? 'url('+ product?.image +')' : 'url(data:image/png;base64,'+ product?.image +')'}"></div>
    <ion-toolbar color="main" class="toolbar-header">
      <ion-segment mode="ios" [(ngModel)]="selectedSegment">
        <ion-segment-button mode="ios" checked value="about">
          <div class="segment-name">About</div>
        </ion-segment-button>
        <ion-segment-button mode="ios" value="top5spots">
          <div class="segment-name">Comments</div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>
  <div [ngSwitch]="selectedSegment" scrollY="true" class="scroll_content">
    <!-- About Tab -->
    <div *ngSwitchCase="'about'" class="content-wrapper about-content">
      <div class="white_div" scrollEvents="true" scrollY="true">
        <div class="detail_flex">
          <ion-icon name="desktop-outline"></ion-icon>
          <ion-label>{{getSite()}}</ion-label> 
          <ion-icon *ngIf="!isGuest" style="margin-left: 10px;" name="share-social-outline" (click)="generateToken()"></ion-icon>
        </div>
        <div class="detail_flex votes-panel" *ngIf="!isGuest">
          <div class='voting'>
            <ion-buttons (click)="sendVote(0)">
              <ion-icon style="color: red;" [class.active-down]="(voteModel | async).voted == 0" class='upButton vote' name="remove-outline"></ion-icon>
            </ion-buttons>
            <h2 class='votesCounter'>{{ (voteModel | async)?.voteSum?.sum ?? 0 }}</h2>
            <ion-buttons (click)="sendVote(1)">
              <ion-icon style="color: green;" [class.active-up]="(voteModel | async).voted == 1" class='active-up downButton vote' name="add"></ion-icon>
            </ion-buttons>
          </div>
        </div>
        <div class="flex_header">
          <ion-label>{{product.title}}</ion-label>
        </div>
        <ion-row justify-content-center align-items-end style="margin: 15px 15px 0 15px;">
          <ion-col col-12 class="button-vertical">
          <ion-button *ngIf="product.site != 'amazon'" expand="block" (click)="openUrl(product.url)">
              <p class="text-p-style">Open Lowest</p>
          </ion-button>
          </ion-col>
        </ion-row>
        <div class="main_container_div" *ngIf="product.site != 'amazon';else shops">
          <div class="flex_div">
              <div class="left_div">
                <div *ngIf="product.discount != 0">
                  <ion-label class="simple_lbl" style="color: #c34448;margin-bottom: 5px;">Cena promocyjna</ion-label>
                  <ion-label class="price" style="color: #c34448;">{{(product.price).toFixed(2)}}zł</ion-label>
                </div>
                <div style="margin-top: 15px;">
                  <ion-label class="simple_lbl" style="color: #000000;margin-bottom: 5px;">Początkowa cena</ion-label>
                  <ion-label class="price" style="color: #000000;">{{(product.oryginalPrice).toFixed(2)}}zł</ion-label>
                </div>             
              </div>
              
              <div class="right_div">
                <div style="display: flex;align-items: center;">
                  <div class="color_div" style="background-color: rgba(41, 101, 191, 0.2);" >
                      <ion-icon name="calendar-outline" style="color: #3f74c8;font-size: 16px;"></ion-icon>
                  </div>
                  <ion-label style="color: #3f74c8; font-size: 15px;">{{product.searchDate | date: 'dd/MM/yyyy'}}</ion-label>
                </div>
                <div style="display: flex;align-items: center; margin-top: 15px;">
                  <div class="color_div" style="background-color: rgba(181, 165, 0, 0.2);">
                      <ion-icon name="arrow-down-sharp" style="color: #7e780c;font-size: 16px;"></ion-icon>
                  </div>
                  <ion-label style="color: #7e780c">-{{(product.discount * 100).toFixed(0)}}%</ion-label>
                </div>
              </div>
          </div>
  
          <div class="month_div" *ngIf="!isGuest">
            <ion-select interface="popover" [(ngModel)]="selectedChartFilter" (ionChange)="changeFilterChart()" class="popover_div">
              <ion-select-option value="w">2 Tygodnie</ion-select-option>
              <ion-select-option value="m">Miesiąc</ion-select-option>
              <!--<ion-select-option value="6m">6 Miesięcy</ion-select-option>
              <ion-select-option value="r">Rok</ion-select-option>-->
            </ion-select>
          </div>
          <div *ngIf="!isGuest;else showGuestDiagram">
            <div *ngIf="!(loadingChart | async);else chartLoading">
              <canvas baseChart style="width: 100%;" height="250" 
                  [data]="lineChartData"
                  [labels]="lineChartLabels"
                  [options]="lineChartOptions"
                  [legend]="lineChartLegend"
                  [type]="lineChartType">
              </canvas>
            </div>
            <ng-template #chartLoading>
              <div class="loadingSpinner">
                <ion-spinner></ion-spinner>
              </div>
            </ng-template>
          </div>
          <ng-template #showGuestDiagram>
            <div *ngIf="!(showComments | async)" class="no-comments">
              <ion-icon name="close-outline"></ion-icon> Wykres cen ukryty-Zaloguj się!
            </div>
          </ng-template>
          
        </div>

        <ng-template #shops>
          <product-amazon *ngIf="product.site == sitesEnum.Amazon" [product]=(product)></product-amazon>
        </ng-template>
      </div>
    </div>

    <div *ngSwitchCase="'top5spots'" class="content-wrapper spots-content">
      <comment-list *ngIf="!isGuest;else showGuestInfo" [addCommentMain]="addCommentMainEmit" [product]="(product)" [addNotify]="addComments" [showComments]="showComments" ></comment-list>
      <ng-template #showGuestInfo>
        <div *ngIf="!(showComments | async)" class="no-comments">
          <ion-icon name="close-outline"></ion-icon> Komentarze ukryte - Zaloguj się!
        </div>
      </ng-template>
      
      <ion-footer *ngIf="!isGuest">
        <div class="footer_div">
          <ion-input type="text" [attr.maxlength]="200" [(ngModel)]="message" placeholder="Type Here.."></ion-input>
          <div class="chat_div" (click)="addComment()">
            <ion-icon name="navigate"></ion-icon>
          </div>
        </div>
      </ion-footer>
    </div>
  </div>
</ion-content>