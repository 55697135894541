import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth/auth.service';
import { SocketIOService } from './services/socket-io/socket-io-client.service';
import { StartViewComponent } from './start-view/start-view.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ProductsService } from './services/products/products.service';
import { AlertHelper } from './helpers/alert-helper';
import { ValidationHelper } from './helpers/validation-helper';
import { SlideMenuViewComponent } from './menu/slide-menu/slide-menu.component';
import { AuthGuardAdminService } from './services/auth/auth-guard-admin.service';
import { CommentListComponent } from './comment-list/commentList.component';
import { CommentComponent } from './comment-list/comment/comment.component';
import { HttpClientModule } from '@angular/common/http';
import { TabsPage } from './tabs/tabs.component';
import { LoadingService } from './services/loading/loading.service';
import { ProductListComponent } from './product/on-view/product-list.component';
import { PaymentViewComponent } from './payment/payment.component';
import { PaymentSuccessViewComponent } from './payment/success-payment/payment-success.component';
import { PayPalService } from './services/paypal/paypal.service';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaymentErrorViewComponent } from './payment/error-payment/payment-error.component';
import { DateAsAgoPipe } from './services/pipe/time-ago.pipe.';
import { AuthGuardGuestService } from './services/auth/auth-guard-guest.service';
import { PlatformService } from './services/platform/platform.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { CategoryFactory } from './models/category/category.model';
import { NgChartsModule } from 'ng2-charts';
import { CommentMenuViewComponent } from './comment-list/comment-menu/comment-menu.component';
import { EditCommandViewComponent } from './comment-list/edit-comment/edit-comment.component';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { LoaderService } from './services/loader/loader.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShareComponent } from './share/share.component';
import { AuthGuardFullGuestService } from './services/auth/auth-guard-full-guest';
import { ProductsGuestService } from './services/guest/products-guest.service';
import { ProductGuestComponent } from './share/product-modal/product.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { FcmService } from './services/FTM/ftm.service';
import { AdMobService } from './services/admob/admob.service';
import { InfoViewComponent } from './info/info.component';
import { ToastService } from './services/toast/toast.service';
import { SliderViewComponent } from './start-view/slider/slider.component';
import { LoginBrowserViewComponent } from 'src/browser/auth/login/login.component';
import { AnchorDirective } from './services/dynamic-loading/dynamic-loader.service';
import { RegisterBrowserViewComponent } from 'src/browser/auth/register/register.component';
import { LoginMobileViewComponent } from 'src/mobile/auth/login/login.component';
import { RegisterMobileViewComponent } from 'src/mobile/auth/register/register.component';
import { ForgotMobileViewComponent } from 'src/mobile/auth/forgot-password/forgot.component';
import { ForgotBrowserViewComponent } from 'src/browser/auth/forgot-password/forgot.component';
import { ChangeMobilePasswordViewComponent } from 'src/mobile/auth/change-password/change-password.component';
import { ChangeBrowserPasswordViewComponent } from 'src/browser/auth/change-password/change-password.component';
import { ShopsBrowserViewComponent } from 'src/browser/other-shops/shops.component';
import { ShopsMobileViewComponent } from 'src/mobile/other-shops/shops.component';
import { NavigationService } from './services/route/navigation.service';
import { NavigationFactoryService } from './services/route/navigation-factory.service';
import { CollectionsMobileViewComponent } from 'src/mobile/other-shops/collections/shops-collections.component';
import { CollectionsListBrowserViewComponent } from 'src/browser/other-shops/collection-list/shops-list.component';
import { CollectionsBrowserViewComponent } from 'src/browser/other-shops/collections/shops-collections.component';
import { CollectionsListMobileViewComponent } from 'src/mobile/other-shops/collection-list/shops-list.component';
import { HeaderViewComponent } from 'src/mobile/header/header.component';
import { SearchFilterMobileComponent } from 'src/mobile/search/search-filter/search-filter.component';
import { ProductMobileComponent } from 'src/mobile/product-modal/product.component';
import { ProductAmazonComponent } from 'src/mobile/product-modal/product-site/product.amazon.component';
import { ProductBrowserComponent } from 'src/browser/product-modal/product.component';
import { HeaderBrowserViewComponent } from 'src/browser/header/header.component';
import { LikedProductsMobileViewComponent } from 'src/mobile/liked-products/liked-products.component';
import { LikedProductsBrowserViewComponent } from 'src/browser/liked-products/liked-products.component';
import { EditProfileMobileViewComponent } from 'src/mobile/profile/edit-profile/edit-profile.component';
import { InviteCodesMobileViewComponent } from 'src/mobile/profile/invite-codes/invite-codes.component';
import { ProfileMobileViewComponent } from 'src/mobile/profile/profile.component';
import { UserDataMobileComponent } from 'src/mobile/profile/uder-data/user-data.componenet';
import { EditProfileBrowserViewComponent } from 'src/browser/profile/edit-profile/edit-profile.component';
import { InviteCodesBrowserViewComponent } from 'src/browser/profile/invite-codes/invite-codes.component';
import { ProfileBrowserViewComponent } from 'src/browser/profile/profile.component';
import { UserDataBrowserComponent } from 'src/browser/profile/uder-data/user-data.componenet';

@NgModule({
    providers: [
        AnchorDirective,
        SocketIOService,
        FcmService,
        AdMobService,
        LoaderService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Clipboard,
        AuthService,
        AuthGuardService,
        AuthGuardGuestService,
        AuthGuardAdminService,
        AuthGuardFullGuestService,
        AlertHelper,
        ValidationHelper,
        ProductsService,
        ProductsGuestService,
        LoadingService,
        PayPalService,
        PlatformService,
        CategoryFactory,
        Deeplinks,
        ScreenOrientation,
        ToastService,
        NavigationFactoryService,
    ],
    declarations: [
        AppComponent,

        //Browser
        HeaderBrowserViewComponent,
        ShopsBrowserViewComponent,
        CollectionsBrowserViewComponent,
        CollectionsListBrowserViewComponent,
        ProductBrowserComponent,
        LikedProductsBrowserViewComponent,
        ProfileBrowserViewComponent,
        UserDataBrowserComponent,
        InviteCodesBrowserViewComponent,
        EditProfileBrowserViewComponent,

        //Mobile
        HeaderViewComponent,
        ChangeMobilePasswordViewComponent,
        ChangeBrowserPasswordViewComponent,
        ShopsMobileViewComponent,
        CollectionsListMobileViewComponent,
        CollectionsMobileViewComponent,
        SearchFilterMobileComponent,
        ProductMobileComponent,
        ProductAmazonComponent,
        LikedProductsMobileViewComponent,
        ProfileMobileViewComponent,
        UserDataMobileComponent,
        InviteCodesMobileViewComponent,
        EditProfileMobileViewComponent,

        //Navigation
        NavigationService,

        StartViewComponent,
        SliderViewComponent,
        ForgotBrowserViewComponent,
        LoginMobileViewComponent,
        RegisterMobileViewComponent,
        SlideMenuViewComponent,
        CommentMenuViewComponent,
        EditCommandViewComponent,
        ForgotMobileViewComponent,
        CommentListComponent,
        CommentComponent,
        TabsPage,
        ProductListComponent,
        PaymentViewComponent,
        PaymentSuccessViewComponent,
        PaymentErrorViewComponent,
        DateAsAgoPipe,
        ShareComponent,
        ProductGuestComponent,
        InfoViewComponent,
        LoginBrowserViewComponent,
        RegisterBrowserViewComponent,
    ],
    imports: [
        BrowserModule,
        ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        NgxPayPalModule,
        NgChartsModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
