<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu is-active"></button></div>
                <div class="page-header__logo"><img src="../../assets/imgs/logo1.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    <div class="search">
                        </div>
                </div>
                <div class="page-header__action" [routerLink]="['/profile']"><a class="profile" href="#!"><img src="assets/imgsN/profile.png" alt="profile"></a></div>
          </div>
        </div>
    </header>
    <div class="page-content">
        <aside class="sidebar is-show" id="sidebar">
            <div class="sidebar-box">
              <ul class="uk-nav">
                <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
                <li class="uk-nav-header">Konto</li>
                <li><a href="04_profile.html" [routerLink]="['/profile']"><i class="ico_profile"></i><span>Profil</span></a></li>
                <li><a href="05_favourites.html" [routerLink]="['/liked']"><i class="ico_favourites"></i><span>Ulubione</span></a></li>
                <li class="uk-nav-header">Portal</li>
                <li><a href="09_games-store.html" [routerLink]="['/dashboard']"><i class="ico_store"></i><span>Kategorie</span></a></li>
                <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
                <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
                <li class="uk-nav-header">Pomoc</li>
                <li><a href="#modal-report" data-uk-toggle><i class="ico_report"></i><span>Więcej o platformie</span></a></li>
                <li><a href="#modal-help" data-uk-toggle><i class="ico_help"></i><span>Pomoc</span></a></li>
            </ul>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-5860375149131207"
     data-ad-slot="6886080818"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>

            </div>
        </aside>

        <main class="page-main">
            <div class="widjet --filters">
                <div class="widjet__body">
                    <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1" style="width: 25%;">
                            <div class="search">
                                <div class="search__input"><i class="ico_search"></i><input (keyup.enter)="onEnter()" (keyup)="onKeySearch($event)" [(ngModel)]="keyword" id="searchBox" type="text" placeholder="Wyszukaj..."></div>
                            </div>
                        </div>
                        <div *ngIf="!isAmazon" style="width: 25%;">
                            <ion-label style="padding: 10px;">Sklepy</ion-label>
                            <ion-item>
                            <ion-select [(ngModel)]="selectedSite" placeholder="Wybierz strony" [multiple]="true">
                                <ion-select-option *ngFor="let site of sites">{{site.site}}</ion-select-option>
                            </ion-select>
                            </ion-item>
                        </div>
                        <div style="width: 25%;">
                            <ion-label class="head_lbl padding-5px">Cena:</ion-label>
                            <ion-grid fixed>
                                <ion-row>
                                <ion-col>
                                    <ion-input type="text" placeholder="Min" [(ngModel)]="filterByPriceMin"></ion-input>
                                </ion-col>
                                <ion-col>
                                    <ion-input type="text" placeholder="Max" [(ngModel)]="filterByPriceMax"></ion-input>
                                </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                        <div style="width: 25%;">
                            <ion-item *ngIf="shopProduct">
                                <ion-label>Pokaż wszystkie produkty</ion-label>
                                <ion-toggle [(ngModel)]="filterShowAllProduct"></ion-toggle>
                              </ion-item>
                        </div>
                        <div style="width: 25%;">
                            <ion-item>
                                <ion-label>Sortuj po:</ion-label>
                                <ion-select [(ngModel)]="++selectedSortBy" placeholder="Sortowanie">
                                  <ion-select-option *ngFor="let item of sortBy" [value]="item.id">{{item.name}}</ion-select-option>
                                </ion-select>
                              </ion-item>
                        </div>
                        <div style="width: 25%;">
                            <ion-item>
                                <ion-label>Minimalna zniżka:</ion-label>
                                <ion-select [(ngModel)]="++filterByDiscountRange" placeholder="Min Discount Procentage">
                                  <ion-select-option *ngFor="let item of filterByDiscountRangeList" [value]="item">{{item}} %</ion-select-option>
                                </ion-select>
                              </ion-item>
                        </div>
                        <div *ngIf="isAmazon || checkAmazon()" style="height: 72%;" style="width: 25%;">
                            <ion-label class="head_lbl padding-5px">Amazon:</ion-label>
                            <ion-item>
                              <ion-label>Dostawa przez Amazon</ion-label>
                              <ion-toggle [(ngModel)]="filterByOnlyAmazon"></ion-toggle>
                            </ion-item>
                          </div>
                        <div>
                            <div class="recommend-slide__btn">
                                <button class="uk-button uk-button-danger" type="button" (click)="startFiltr()">Filtruj</button>
                                <button class="uk-button uk-button-danger" style="margin-left: 2px;" type="button" (click)="getNextItems($event, true, false)">Odśwież</button>
                            </div>
                                              
                        </div>
                    </div>
                </div>
            </div>
            <ion-content style="height: 80%;" [scrollEvents]="true" (ionScroll)="listContentScrollEvent($event)">
                <div *ngIf="(productList | async).length > 0">
                    <div class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small" data-uk-grid>
                        <div *ngFor="let item of (productList | async); let i = index">
                            <ins *ngIf="i % 20 == 0;else showProd" class="adsbygoogle"
                                style="display:block;width: 385px;
                                height: 300px;"
                                data-ad-format="fluid"
                                data-ad-layout-key="-6t+ed+2i-1n-4w"
                                data-ad-client="ca-pub-5860375149131207"
                                data-ad-slot="6656062249"></ins>
                                <ng-template #showProd>
                                    <div class="game-card">
                                        <div class="game-card__box">
                                            <div class="game-card__media" style="background-size: cover;" [ngStyle]="{'backgroundImage': item?.image?.length < 100 ? 'url(assets/imgs/szoppop.jpg)' : item?.image?.includes('data:image') ? 'url('+ item?.image +')' : 'url(data:image/png;base64,'+ item?.image +')'}"  (click)="openProduct(item)"></div>
                                            <div class="game-card__info"><a class="game-card__title" href="10_game-profile.html"> {{item.title | slice:0:80}}</a>
                                                <div class="game-card__genre" *ngIf="item.site != 'amazon'">{{item.site}}</div>
                                                <div class="game-card__genre" *ngIf="item.site == 'amazon'">{{item.site}} - {{item.lowest.country | uppercase}}</div>
                                                <div class="game-card__rating-and-price">
                                                    <div class="game-card__rating" *ngIf="item.site != 'amazon' && item?.discount != 0"><span>-{{(item.discount * 100).toFixed(0)}}%</span></div>
                                                    <div class="game-card__rating" *ngIf="item.site == 'amazon' && item?.lowest?.discount != 0"><span>-{{(item?.lowest?.discount * 100).toFixed(0)}}%</span></div>
                                                    <div class="game-card__rating" *ngIf="(item?.discount == 0 || item?.discount == undefined) && (item?.lowest?.discount == 0 || item?.lowest?.discount == undefined)"><span>-0%</span></div>
                                                    <div class="game-card__price"><span>{{item.price ?? item.lowest.price}}zł</span></div>
                                                </div>
                                                <div class="game-card__bottom">
                                                    <div class="game-card__platform" style="font-size: 15px">
                                                        <p>{{ item.voteSum }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                
                            
                        </div>
                    </div>
                </div>
                
                <ion-infinite-scroll *ngIf="!(endProduct | async)" (ionInfinite)="doInfinite($event)">
                    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Ładuję kolejne produkty...">
                    </ion-infinite-scroll-content>
                  </ion-infinite-scroll>
                  <div *ngIf="(endProduct | async)" class="no-product">
                    Brak produktów!
                  </div>
                  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                    <ion-fab-button (click)="scrollUp()" class="button-float">
                      <ion-icon name="chevron-up-circle"></ion-icon>
                    </ion-fab-button>
                  </ion-fab>
            </ion-content>
        </main>
    </div>
    