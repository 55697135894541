import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { InviteCode } from "src/app/models/products/invite-code.model";
import { ProductsModel, CommentModel, CommentVote } from "src/app/models/products/product.model";
import { SocketIOService } from "../socket-io/socket-io-client.service";
import { CountryFilter, SiteFilter, CategoryModel } from '../../models/dashboard/filter.model';
import { VoteProductModel } from "src/app/models/products/vote-product.model";
import { UserStats, UserData } from '../../models/user.model'
import { ShopCategoryResponse } from "src/app/models/category/models/dbCategory.model";
import { ArchivProduct } from "src/app/models/products/archivProduct.model";
import { BannerList } from "src/app/models/dashboard/banner.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ProductsService {
    private url = "http://138.2.159.102:3000";
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
    constructor(private http: HttpClient, private socketService: SocketIOService, private alertController: AlertController){

    }

    public async getLastProducts(page: number = 1, limit: number = 10, page_to_skip: number = 0, 
        countryList: string[], minPrice: string | number, maxPrice: string | number, 
        onlyAmazon: string |boolean, discountRange: string | number, site: string[],
        searchItem?: undefined | string) : Promise<ProductsModel[]> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getLastProducts', page,limit, page_to_skip, 
            countryList, minPrice, maxPrice, onlyAmazon, discountRange, site, searchItem, (json: string) =>{
                var productsArray: ProductsModel[] = [];
                let arrayProducts = JSON.parse(json.toString());  
               
                if(arrayProducts.length > 0){
                    arrayProducts.forEach((element) =>{
                        var product = element as ProductsModel;
                        product.liked = element.liked;
                        productsArray.push(product);
                    });
                }
                
                resolve(productsArray);
            });
        })

        return result as ProductsModel[];
    }

    public async getShopCategoryProducts(category: string, tags: string[], page: number = 1, limit: number = 10, page_to_skip: number = 0, 
        minPrice: string | number, maxPrice: string | number, 
        discountRange: string | number, site: string[], allProduct: boolean | string = undefined, 
        sortBy: number | string, searchItem: undefined | string, countryList: CountryFilter[], onlyAmazon: string |boolean) : Promise<ProductsModel[]> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getShopCategoryProducts',category, tags, page,limit, page_to_skip, 
            minPrice, maxPrice, discountRange, site, allProduct, sortBy, searchItem, countryList.filter(x => x.checked).map(x => x.country), onlyAmazon, (json: string) =>{
                var productsArray: ProductsModel[] = [];
                let arrayProducts = JSON.parse(json.toString());  
                if(arrayProducts.length > 0){
                    arrayProducts.forEach((element) =>{
                        var product = element as ProductsModel;
                        product.liked = element.liked;
                        productsArray.push(product);
                    });
                }
                
                resolve(productsArray);
            });
        })

        return result as ProductsModel[];
    }

    public async getInviteTokens() {
        var result: InviteCode[] = await new Promise(resolve => {
            this.socketService.getSocket().emit('getInvitedCodes', (result: string) =>{
                let arrayTokens: InviteCode[] = JSON.parse(result.toString());
                resolve(arrayTokens);
            });
        });

        return result;
    }

    public async getUserStats() : Promise<UserStats>{
        var result: UserStats = await new Promise(resolve => {
            this.socketService.getSocket().emit('getUserStats', (result: UserStats) =>{
                resolve(result);
            });
        });

        return result;
    }

    public async getBannerList() : Promise<BannerList[]>{
        var result: BannerList[] = await new Promise(resolve => {
            this.socketService.getSocket().emit('getBannerList', (result: BannerList[]) =>{
                resolve(result);
            });
        });

        return result;
    }

    public async getUserData() : Promise<UserData>{
        var result: UserData = await new Promise(resolve => {
            this.socketService.getSocket().emit('getUserData', (result: UserData) =>{
                resolve(result);
            });
        });

        return result;
    }

    public async changeUserData(toChange: string, value: string) : Promise<any>{
        var result: any = await new Promise(resolve => {
            this.socketService.getSocket().emit('changeUserData', toChange, value, (result: any) =>{
                resolve(result);
            });
        });

        return result;
    }

    public async getSearchProducts( searchQuery: string, countryList: string[], minPrice: number, maxPrice: number,
                                    onlyAmazon: boolean, discountRange: number, category: CategoryModel, site: SiteFilter, 
                                    toSkip: number = 1, limit: number = 10) : Promise<ProductsModel[]> {
                                        
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('searchProducts',searchQuery, countryList, minPrice, maxPrice, onlyAmazon, discountRange, category.id, site.site, toSkip, limit, (json: string) =>{
                var productsArray: ProductsModel[] = [];
                let arrayProducts = JSON.parse(json.toString());     
                if(arrayProducts.length > 0){
                    arrayProducts.forEach( (element) =>{
                        var product = element as ProductsModel;
                        product.liked = element.liked;
                        productsArray.push(product);
                    });
                }

                resolve(productsArray);
            });
        })

        return result as ProductsModel[];
    }

    public async addLikedProduct(product: ProductsModel) : Promise<number> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('addLikedProduct',product._id, (id: number) =>{
                resolve(id);
            });
        })

        return result as number;
    }

    public async getLikedProduct(toSkip: number = 1, limit: number = 10) : Promise<ProductsModel[]> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getLikedProduct', toSkip, limit, (json: string) =>{
                var productsArray: ProductsModel[] = [];
                let arrayProducts = JSON.parse(json.toString());    
                if(arrayProducts.length > 0){
                    arrayProducts.forEach( (element) =>{
                        productsArray.push(element as ProductsModel);
                    });
                }
                resolve(productsArray);
            });
        })

        return result as ProductsModel[];
    }

    public async getComments(productId: string, toSkip: number = 0, limit: number = 10)  : Promise<CommentModel[]> {
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getComments', productId, toSkip, limit, (json: string) =>{
                if(json != "-2"){
                    let commenstsArray = JSON.parse(json.toString()) as CommentModel[];    
                    resolve(commenstsArray);
                }
                else{
                    resolve(CommentModel[0])
                }
            });
        });
        return result as CommentModel[];
    }

    public async addComment(comment: CommentModel): Promise<string | boolean>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('addComment', comment, (message: string) =>{
                if(message == "1"){
                    resolve(true);
                }
                else if(message == "-1"){
                    resolve(false);
                }
                else if(message == "-2"){
                    resolve(false);
                }
                else{
                    resolve(message);
                }
            });
        });

        return result as string | boolean;
    }

    public async editComment(comment: CommentModel): Promise<string | boolean>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('editComment', comment, (result: string) =>{
                if(result == "1"){
                    resolve(true);
                }
                else if(result == "-1"){
                    resolve(false);
                }
                else if(result == "-2"){
                    resolve(false);
                }
                else{
                    resolve(result);
                }
            });
        });

        return result as string | boolean;
    }

    public async canEditComment(comment: CommentModel): Promise<boolean>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('canEditOrDeleteComment', comment, (result: string) =>{
                if(result == "1"){
                    resolve(true);
                }
                else if(result == "-1"){
                    resolve(false);
                }
                else if(result == "-2"){
                    resolve(false);
                }
            });
        });

        return result as boolean;
    }

    public async deleteComment(comment: CommentModel): Promise<boolean>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('deleteComment', comment, (result: string) =>{
                if(result == "1"){
                    resolve(true);
                }
                else if(result == "-1"){
                    resolve(false);
                }
                else if(result == "-2"){
                    resolve(false);
                }
            });
        });

        return result as boolean;
    }

    public async addCommentVote(commentId: string, status: number): Promise<boolean>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('voteComment', commentId, status, (result: string) =>{
                if(result == "1"){
                    resolve(true);
                }
                else if(result == "-1"){
                    resolve(false);
                }
                else if(result == "-2"){
                    resolve(false);
                }
            });
        });

        return result as boolean;
    }

    public async getCommentVoteCount(commentId: string): Promise<CommentVote>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getCommentVoteCount', commentId, (message: CommentVote) =>{
                resolve(message as CommentVote);
            });
        });

        return result as CommentVote;
    }

    public async generateProductToken(productId: string): Promise<string>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('generateProductToken', productId, (message: string) =>{
                resolve(message as string);
            });
        });

        return result as string;
    }

    public async getProductByToken(tokenVal: string): Promise<ProductsModel>{
        var result = await new Promise(resolve => {
            var token = {
                token: tokenVal.toString()
            }
            this.http.post<ProductsModel>(this.url + "/getProductByToken", token, this.httpOptions)
                .subscribe(async data => {
                    var checkUser = data as ProductsModel;
                    if(checkUser){
                        resolve(checkUser);
                    }
                }, error => {
                    resolve(error.error.text);
                });
        });

        return result as ProductsModel;
    }

    public async addVote(productId: string, status: number): Promise<string>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('voteProduct', productId, status, (message: string) =>{
                resolve(message);
            });
        });

        return result as string;
    }

    public async getVoteCount(productId: string): Promise<VoteProductModel>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getVoteSumProduct', productId, (message: VoteProductModel) =>{
                resolve(message as VoteProductModel);
            });
        });

        return result as VoteProductModel;
    }

    public async getArchivData(product: ProductsModel): Promise<ArchivProduct[] | undefined>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getArchivData', product, (message: ArchivProduct[] | undefined) =>{
                resolve(message as ArchivProduct[] | undefined);
            });
        });

        return result as ArchivProduct[] | undefined;
    }

    public async getCategory(): Promise<ShopCategoryResponse[]>{
        var result = await new Promise(resolve => {
            this.socketService.getSocket().emit('getCategory', (message: ShopCategoryResponse[]) =>{
                resolve(message as ShopCategoryResponse[]);
            });
        });

        return result as ShopCategoryResponse[];
    }

    private checkBefore(): boolean{
        return this.socketService.serverStatus.getValue();
    }

    async serverError(info?: string) {
        var error = await this.alertController.create({
          header: 'Sorry, but...',
          message: info,
          buttons: ['Ok'],
        });
    
        await error.present();
    }
}