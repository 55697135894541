<div class="page-wrapper">
    <header class="page-header">
        <div class="page-header__inner">
            <div class="page-header__sidebar">
                <div class="page-header__menu-btn"><button class="menu-btn ico_menu is-active"></button></div>
                <div class="page-header__logo"><img src="../../assets/imgs/logo1.png" alt="logo"></div>
            </div>
            <div class="page-header__content">
                <div class="page-header__search">
                    <div class="search">
                        </div>
                </div>
                <div class="page-header__action" [routerLink]="['/profile']"><a class="profile" href="#!"><img src="assets/imgsN/profile.png" alt="profile"></a></div>
          </div>
        </div>
    </header>
    <div class="page-content">
      <aside class="sidebar is-show" id="sidebar">
          <div class="sidebar-box">
            <ul class="uk-nav">
              <li [routerLink]="['/dashboard']"><a href=""><i class="ico_home"></i><span>Strona Główna</span></a></li>
              <li class="uk-nav-header">Konto</li>
              <li class="uk-active"><a href="04_profile.html" [routerLink]="['/profile']"><i class="ico_profile"></i><span>Profil</span></a></li>
              <li><a href="05_favourites.html" [routerLink]="['/liked']"><i class="ico_favourites"></i><span>Ulubione</span></a></li>
              <li class="uk-nav-header">Portal</li>
              <li><a href="09_games-store.html" [routerLink]="['/dashboard']"><i class="ico_store"></i><span>Kategorie</span></a></li>
              <li><a href="09_games-store.html" [routerLink]="['/list']" [queryParams]="{ type: 'Ostatnie', main: ''}"><i class="ico_store"></i><span>Ostatnio znalezione</span></a></li>
              <li><a href="11_market.html" [routerLink]="['/list']" [queryParams]="{ type: 'Amazon', main: 'undefined'}"><i class="ico_market"></i><span>Amazon</span></a></li>
              <li class="uk-nav-header">Pomoc</li>
              <li><a href="#modal-report" data-uk-toggle><i class="ico_report"></i><span>Więcej o platformie</span></a></li>
              <li><a href="#modal-help" data-uk-toggle><i class="ico_help"></i><span>Pomoc</span></a></li>
          </ul>
          </div>
      </aside>
      <main class="page-main">
        <div class="uk-grid" data-uk-grid>
            <div class="uk-width-2-3@l">
                <div class="widjet --profile">
                    <div class="widjet__head">
                        <h3 class="uk-text-lead">Profile</h3>
                    </div>
                    <div class="widjet__body">
                        <div class="user-info">
                            <div class="user-info__avatar" style="background-size: cover;" [style.backgroundImage]="'url(assets/imgsN/profile.png)'"></div>
                            <div class="user-info__box">
                                <div class="user-info__title">{{(userData | async).nick}}</div>
                                <div class="user-info__text">{{(userData | async).email}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="widjet --bio">
                    <div class="widjet__head">
                        <h3 class="uk-text-lead">Generalne</h3>
                    </div>
                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToEditProfile()">
                            <div class="widjet-game">
                                <div class="color_box">
                                    <img src="assets/imgs/settings.png"/>
                                </div>
                                <div class="widjet-game__info">Edytuj profil
                                    <div class="widjet-game__last-played">Edytuj podstawowe funkcje profilu</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToChangePassword()">
                            <div class="widjet-game">
                                <div class="color_box">
                                    <img src="assets/imgs/privacy.png"/>
                                </div>
                                <div class="widjet-game__info"> Zmiana hasła
                                    <div class="widjet-game__last-played">Zmień swoje hasło</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widjet__body">
                        <div class="widjet__body" (click)="goToInvideCodes()">
                            <div class="widjet-game">
                                <div class="color_box">
                                    <img src="assets/imgs/badge_3.png"/>
                                </div>
                                <div class="widjet-game__info"> Kody zaproszenia
                                    <div class="widjet-game__last-played">Kody dla znajomych</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
  </div>