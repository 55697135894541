import { ShopSubCategoryResponse } from "./dbCategory.model";

export var popular: ShopSubCategoryResponse[] = [
    {
        img : 'Ostatnie',
        Category: "Ostatnie",
    },
    {
        img : 'Amazon',
        Category: "Amazon",
    }
    /*,
    {
        img : 'najlepiejoceniane',
        Category: "Najlepiej oceniane",
    }*/
];