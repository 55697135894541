import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardGuestService {  
    constructor(private navCtrl: NavController, public auth: AuthService, public router: Router) {}  
    canActivate(): boolean {
        if (!this.auth.isAuth.getValue()) {
            return true;
        }
        
        this.navCtrl.navigateRoot(['dashboard']);
        return false;
    }
}