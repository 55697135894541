import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertHelper } from 'src/app/helpers/alert-helper';
import { InviteCode } from 'src/app/models/products/invite-code.model';
import { UserData } from 'src/app/models/user.model';
import { AdMobService } from 'src/app/services/admob/admob.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { EditProfileMobileViewComponent } from './edit-profile/edit-profile.component';
import { UserDataMobileComponent } from './uder-data/user-data.componenet';

@Component({
  selector: 'profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMobileViewComponent implements OnInit {
  public tokens = new BehaviorSubject<InviteCode[]>([]);
  public userData = new BehaviorSubject<UserData>(new UserData());
  public tabs: string[] = [];
  public tabIndex: number;

  public isSending = new BehaviorSubject<boolean>(true);
  public isSending$ = new Observable<boolean>();

  constructor(private navCtrl: NavController, 
    public modalCtrl: ModalController,
    private admob: AdMobService,
    public loaderService: LoaderService,
    private alertHelper: AlertHelper,
    private authService: AuthService,
    private productService: ProductsService) {
    this.tabs = ["Invite Codes", "General"];
    this.tabIndex = 0;
  }

  ngOnInit() {
    this.admob.hideBanner();
    this.loaderService.createLoader();
    this.productService.getUserData().then((response) =>{
      console.log(response);
      
      this.userData.next(response);
      this.loaderService.dimissLoader();
    });

    this.isSending$ = this.isSending.asObservable();
  }

  public segmentChange(event) {
    const newtab = event._value;
  }

  public selectTab(slide, index: number) {
    slide.slideTo(index);
    slide.getActiveIndex().then(index => {
      this.tabIndex = index;
    });
  }

  async goToEditProfile(){
  const modalView = await this.modalCtrl.create({
    component: EditProfileMobileViewComponent,
    componentProps: {
      'userData': this.userData.getValue(),
    }
  });

  await modalView.present();

}

goToChangePassword() {
  this.navCtrl.navigateRoot('password-forgot');
}

goToInvideCodes(){
  this.navCtrl.navigateRoot('invite');
}

logout(){
  this.authService.logout();
}

  goBack()  {
    this.navCtrl.back();
  }

  public async changePassword(){
    this.isSending.next(!this.isSending.getValue());
    var result = await this.authService.createSendCodechangePassword();
    
    if(!!result === true){
      this.navCtrl.navigateRoot('password-change');
    }else{
      this.alertHelper.presentDefaultAlert("Insufficient data", result as string, ["Try again"]);
    }
    this.isSending.next(!this.isSending.getValue());
  }

  public async change(toChange: string){
    if(toChange == 'nick'){
      this.changeData(toChange, this.userData.getValue().nick);
    }

    if(toChange == 'email'){
      this.changeData(toChange, this.userData.getValue().email);
    }
  }

  private async changeData(toChange: string, value: string){
    const modal = await this.modalCtrl.create({
      component: UserDataMobileComponent,
      componentProps: {
        'changingPropertyName': toChange,
        'changingPropertyValue': value,
      }
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if(data != undefined && data.successful){
      var uData = this.userData.getValue();

      if(toChange == 'nick'){
        uData.nick = data.newValue;
      }
  
      if(toChange == 'email'){
        uData.email = data.newValue;
      }
      this.userData.next(uData);
    }
  }
}