import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AdMobService } from '../admob/admob.service';
import { DisallowedPage } from '../admob/page.model';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService {  
    private clickCount = 0;
    constructor(private admob: AdMobService ,private navCtrl: NavController, public auth: AuthService, public router: Router) {
        this.clickCount = 0;
    }  
    canActivate(route: ActivatedRouteSnapshot): boolean {
        var routeIndex = route.url.findIndex(x => DisallowedPage.includes(x.path));
        if(routeIndex > -1){
            this.admob.hideBanner();
        }else{
            if(this.admob.showingBanner.getValue() == false){
                this.admob.showBaner();
            }
        }
        if(this.clickCount > 10){
            this.clickCount = 0;
            this.admob.showInterstitial();
        }
        
        this.clickCount++;
        if (!this.auth.isAuth.getValue()) {
            this.navCtrl.navigateRoot(['login']);
            return false;
        }
        return true;
    }
}