<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar (click)="unfocusSearch()">
        <ion-title><ion-img src="assets/imgs/logo1.png" class="img-logo" ></ion-img></ion-title>
    </ion-toolbar>
    <div class="header_search">
      <ion-input (keyup.enter)="onEnter()" [(ngModel)]="searchTextValue" id="searchBar" (ionFocus)="focusSearch()"  (keyup)="onKeySearch($event)" (click)="startSearch()" type="text" placeholder="Wyszukaj...">
          <ion-icon name="search"></ion-icon>
      </ion-input>
      <div id="searchList" class="searchList">
        <ion-content>
          <ion-list>
            <div *ngIf="!(searchTagsLoading | async);else searchTagsElse">
              <div *ngIf="(startSearching | async)">
                <ion-item *ngFor="let item of (searchTags | async)">
                  <ion-label 
                  [routerLink]="['/list']" 
                  [queryParams]="{ type: ''+item.Category+'', main: ''+item.Main+'', tag: ''+item.Tag+''}">{{item.Category}} -> {{item.Tag}}</ion-label>
                </ion-item>
              </div>
            </div>
            <ng-template #searchTagsElse>
              <div class="loadingSpinnerTag">
                <ion-spinner></ion-spinner>
              </div>
            </ng-template>
          </ion-list>
          <ion-infinite-scroll (ionInfinite)="tagInfinite($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </div>
    </div>
  </ion-header>
  
  <ion-content (click)="unfocusSearch()">
    <div class="main_content_div">
  
        <ion-slides pager="ios" class="home_slider">
            <ion-slide *ngFor="let item of bannerList | async" >
                <div class="slider_back" [style.backgroundImage]="'url('+ item.img +')'">
                  <div class="gradient_div">
                      <div class="abs_div">
                          <ion-label class="bold_lbl">{{item.title}}</ion-label>
                          <ion-label class="small_lbl">{{item.description}}</ion-label>
                      </div>
                  </div>
                </div>
            </ion-slide>
        </ion-slides>
  <div>
    <div class="content_div">
      <div class="header_div">
          <ion-label Class="title_lbl">Popularne filtry</ion-label>
          <ion-label Class="view_lbl" [routerLink]="['/collection']" [queryParams]="{ type: 'Popular'}" >Zobacz wszystkie</ion-label>
      </div>

      <div class="custom_slider">
          <div class="slide_box" *ngFor="let item of popularCategory | async" 
          [routerLink]="['/list']" 
          [queryParams]="{ type: ''+item.Category+'', main: ''+item.MainCategory+''}">
          <div class="slide1_img" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item) +'.jpg)'"></div>
              <div class="detail1_div">
                  <ion-label class="bold_lbl">{{item.Category}}</ion-label>
              </div>
          </div>
      </div>
      <div *ngIf="!(searchCategoryLoading | async);else loading">
        <div *ngFor="let item of (categoryFactory.category | async)">
          <div class="header_div">
              <ion-label Class="title_lbl">{{item.MainCategory}}</ion-label>
              <ion-label Class="view_lbl" [routerLink]="['/collection']" [queryParams]="{ type: ''+item.MainCategory+''}" >Zobacz wszystkie</ion-label>
          </div>

          <div class="custom_slider">
              <div class="slide_box" *ngFor="let item2 of item.Categories | slice:0:6" 
              [routerLink]="['/list']" 
              [queryParams]="{ type: ''+item2.Category+'', main: ''+item.MainCategory+''}">
              <div class="slide2_img" [style.backgroundImage]="'url(assets/imgs/'+ prepareImage(item2) +'.jpg)'">
                <div class="gradient_div">
                    <div class="detail2_div">
                      <div class="yellow_div"></div>
                      <ion-label class="bold_lbl">{{item2.Category}}</ion-label>
                    </div>
                </div>
                </div>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>
        <ng-template #loading>
          <div class="loadingSpinner">
              <ion-spinner></ion-spinner>
            </div>
        </ng-template>
    </div>
  </ion-content>