import { Injectable } from "@angular/core";
import { AlertHelper } from "./alert-helper"

@Injectable()
export class ValidationHelper {
    constructor(private alertHelper: AlertHelper){
    }

    private forbiddenWords: string[] = ["anal", "anus", "arse", "ass", "ballsack", "balls", "bastard", "bitch", "biatch", "bloody", "blowjob", "blow job", "bollock", "bollok", "boner", "boob", "bugger", "bum", "butt", "buttplug", "clitoris", "cock", 
    "coon", "crap", "cunt", "damn", "dick", "dildo", "dyke", "fag", "feck", "fellate", "fellatio", "felching", "fuck", "f u c k", "fudgepacker", "fudge packer", "flange", "Goddamn", "God damn", "hell", "homo", "jerk", "jizz", "knobend", "knob end", 
    "labia", "lmao", "lmfao", "muff", "nigger", "nigga", "omg", "penis", "piss", "poop", "prick", "pube", "pussy", "queer", "scrotum", "sex", "shit", "s hit", "sh1t", "slut", "smegma", "spunk", "tit", "tosser", "turd", "twat", "vagina", "wank", "whore", "wtf"];

    public validateNick(nickName: string){
        if(!nickName){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika jest wymagana.", ["Spróbuj ponownie"]);
            return false;
        }

        if(!(nickName.length >= 6 && nickName.length <= 30)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika mieć od 6 do 30 znaków.", ["Spróbuj ponownie"]);
            return false;
        }
    
        if(!this.isNickNameCorrect(nickName)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Nazwa użytkownika musi zaczynać się od liter (A-Z lub a-z).", ["Spróbuj ponownie"]);
            return false;
        }
        
        return true;
    }

    public validatePassword(password: string){
        if(!password){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło jest wymagane.", ["Spróbuj ponownie"]);
            return false;
        }
      
        if(!(password.length >= 6 && password.length <= 30)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło musi mieć od 6 do 30 znaków.", ["Spróbuj ponownie"]);
            return false;
        }
      
        if(!this.isPasswordCorrect(password)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Hasło jest nie poprawne, poprawne hasło musi posiadać przynjmnie jedną duż literę (A-Z), małą literę: (a-z) i liczbę: (0-9).", ["Spróbuj ponownie"]);
            return false;
        }

        return true;
    }

    public validateEmail(email: string){
        if(!email){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "E-mail jest wymagany.",["Spróbuj ponownie"]);
            return false;
        }

        if(!this.isEmailCorrect(email)){
            this.alertHelper.presentDefaultAlert("Niepoprawne dane", "Email jest niepoprawny.", ["Spróbuj ponownie"]);
            return false;
        }

        return true;
    }

    public validateComment(message: string){
        message = message.replace(/\s\s+/g, ' ');
        if(message == ' ' || message == ''){
            return "Komentaż nie może być pusty";
          }
        if(message.length < 2 || message.length > 200){
            this.alertHelper.presentDefaultAlert("Zbyt mało danych", "Komentaż może mieć od 2 do 200 znaków.", ["Ok"]);
            return false;
        }
        if(this.checkMessageForForbidenWords(message)){
            return false;
        }
      
        return true;
    }

    public isNickNameCorrect(nickName: string) : boolean {
        var correct = true;
        correct = correct && /^[a-zA-Z]{1}/i.test(nickName);
        //correct = correct && /([0-9]+)/i.test(nickName);
        //correct = correct && /([a-z]+)/i.test(nickName);
        //correct = correct && /([A-Z]+)/i.test(nickName);
        //correct = correct && /([,.!*+-]+)/i.test(nickName);

        return correct;
    }

    public isPasswordCorrect(password: string) : boolean {
        var correct = true;
        correct = correct && /^[a-zA-Z]{1}/i.test(password);
        correct = correct && /([0-9]+)/i.test(password);
        correct = correct && /([a-z]+)/i.test(password);
        correct = correct && /([A-Z]+)/i.test(password);
        //correct = correct && /([,.!*+-]+)/i.test(password);

        return correct;
    }

    public isEmailCorrect(email: string) : boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email);
    }

    private checkMessageForForbidenWords(message: string) : boolean{
        var flag = false;
        this.forbiddenWords.forEach(word => {
            flag = message.includes(word);
            if(flag) {
                this.alertHelper.presentDefaultAlert("Zakazane słowo", "Komentaż zawiera zakazane słowo " + word + " lub podobne słowo, usuń je żeby wysłać komentaż.", ["Ok"]);
                return;
            }
        });

        return flag;
    }
}


